export enum MessageType {
  REMIND_CLIENT = "REMIND_CLIENT",
  COMMENT_NOTIFICATION = "COMMENT_NOTIFICATION",
  KICK_OFF = "KICK_OFF",
}

export const MessageTypeDisplay = {
  [MessageType.REMIND_CLIENT]: "Remind client",
  [MessageType.COMMENT_NOTIFICATION]: "Comment notification",
  [MessageType.KICK_OFF]: "New financial year",
};
