import { useMemo } from "react";

export class SessionStorageService {
  public getClientStatusFilter(): string | null {
    const key = this.getClientStatusFilterKey();
    return sessionStorage.getItem(key);
  }

  public setClientStatusFilter(statusFilter: string | undefined): void {
    const key = this.getClientStatusFilterKey();
    if (statusFilter) sessionStorage.setItem(key, statusFilter);
    else sessionStorage.removeItem(key);
  }

  private getClientStatusFilterKey(): string {
    return `client-statusFilter`;
  }

  public getClientHideCompleted(): string | null {
    const key = this.getClientHideCompletedKey();
    return sessionStorage.getItem(key);
  }

  public setClientHideCompleted(hideCompleted: boolean): void {
    const key = this.getClientHideCompletedKey();
    if (hideCompleted) sessionStorage.setItem(key, JSON.stringify(hideCompleted));
    else sessionStorage.removeItem(key);
  }

  private getClientHideCompletedKey(): string {
    return `client-hideCompleted`;
  }

  public getClientSortBy(): string | null {
    const key = this.getClientSortByKey();
    return sessionStorage.getItem(key);
  }

  public setClientSortBy(sortBy: string): void {
    const key = this.getClientSortByKey();
    sessionStorage.setItem(key, sortBy);
  }

  private getClientSortByKey(): string {
    return `client-sortBy`;
  }

  public getClientSortDirection(): string | null {
    const key = this.getClientSortDirectionKey();
    return sessionStorage.getItem(key);
  }

  public setClientSortDirection(sortDirection: string): void {
    const key = this.getClientSortDirectionKey();
    sessionStorage.setItem(key, sortDirection);
  }

  private getClientSortDirectionKey(): string {
    return `client-sortDirection`;
  }

  public clearStorage(): void {
    sessionStorage.clear();
  }
}

const useSessionStorageService = () => {
  return useMemo(() => new SessionStorageService(), []);
};

export default useSessionStorageService;
