import { useMemo } from "react";
import { MessageType, MessageTypeDisplay } from "../enums/MessageType";

const REMINDER_CLIENT_TEMPLATE = `Dear {{clientName}},

I hope you had a nice weekend.

I just wanted to follow up on the information we need to complete your {{activePeriodName}} tax return.

Your return is due for lodgement {{lodgementDate}} and therefore we need you to upload your tax information and answer the questions in Taxy {{dueDate}}.

Please feel free to give me a call with any questions.

Kind regards,
{{accountantFirstName}} {{accountantLastName}}`;

const COMMENT_NOTIFICATION_TEMPLATE = `Dear {{clientName}},

I left you a comment in Taxy regarding your tax return. At your convenience could you please login to Taxy and review and respond?

Please feel free to give me a call with any questions.

Kind regards,
{{accountantFirstName}} {{accountantLastName}}`;

const KICK_OFF_TEMPLATE = `Dear {{clientName}},

I've set up the new financial year for you in Taxy. You can go ahead and start adding your documents as the year progresses.

Please feel free to give me a call with any questions.

Kind regards,
{{accountantFirstName}} {{accountantLastName}}`;

export class MessageTemplate {
  private labels: Record<MessageType, string> = MessageTypeDisplay;

  private templates: Record<MessageType, string> = {
    [MessageType.REMIND_CLIENT]: REMINDER_CLIENT_TEMPLATE,
    [MessageType.COMMENT_NOTIFICATION]: COMMENT_NOTIFICATION_TEMPLATE,
    [MessageType.KICK_OFF]: KICK_OFF_TEMPLATE,
  };

  public getLabel(type: MessageType): string {
    return this.labels[type];
  }

  public getTemplate(type: MessageType): string {
    return this.templates[type];
  }
}

const useMessageTemplate = () => {
  return useMemo(() => new MessageTemplate(), []);
};

export default useMessageTemplate;
