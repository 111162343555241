import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ReplyIcon from "@mui/icons-material/ReplyOutlined";
import Button from "@mui/material/Button";
import { darken } from "@mui/material/styles";
import { ReactNode } from "react";
import { useApplicationState } from "../../../hooks/ApplicationState";
import { RequirementStatusType, RequirementType } from "../../../types";
import Hotkey from "../../Hotkey";

type ActionButtonProps = {
  label: string;
  loading: boolean;
  color: string;
  disabled?: boolean;
  icon: ReactNode;
  hotkey?: ReactNode;
  onClick: () => Promise<void>;
};

const ActionButton = ({ label, loading, color, icon, hotkey, onClick, disabled = false }: ActionButtonProps) => {
  return (
    <Button
      variant="contained"
      endIcon={hotkey ? null : icon}
      sx={{
        backgroundColor: color,
        color: "text.primary",
        "&:hover": {
          backgroundColor: darken(color, 0.2),
        },
        ml: 1,
        gap: hotkey ? "0.5rem" : null,
      }}
      disabled={loading || disabled}
      onClick={onClick}
      className="actionButton"
    >
      {label}
      {hotkey}
    </Button>
  );
};

type RequirementActionButtonsProps = {
  requirement: RequirementType;
  onUpdate: (status: RequirementStatusType) => Promise<void>;
  loading: boolean;
};

// TODO - remove custom colours on buttons and add to theme
const RequirementActionButtons = ({ requirement, loading, onUpdate }: RequirementActionButtonsProps) => {
  const { isAccountant } = useApplicationState();

  const sendToClient = async () => {
    await onUpdate("WITH_CLIENT");
  };

  const sendToAccountant = async () => {
    await onUpdate("WITH_ACCOUNTANT");
  };

  const approve = async () => {
    await onUpdate("COMPLETE");
  };

  const getButtons = () => {
    const buttons: {
      id: string;
      label: string;
      icon: ReactNode;
      hotkey?: ReactNode;
      color: string;
      onClick: () => Promise<void>;
    }[] = [];

    // ACCOUNTANT BUTTONS
    if (isAccountant) {
      if (requirement.status !== "WITH_CLIENT") {
        buttons.push({
          id: "backToClient",
          label: "Back to client",
          icon: <ReplyIcon />,
          color: "#ccc",
          onClick: sendToClient,
        });
      }

      if (requirement.status === "COMPLETE") {
        buttons.push({
          id: "removeApproval",
          label: "Remove approval",
          icon: <ClearIcon />,
          color: "#ccc",
          onClick: sendToAccountant,
        });
      } else {
        buttons.push({
          id: "approve",
          label: "Approve",
          icon: <CheckIcon />,
          hotkey: <Hotkey hotkey="enter" includeModifier onTrigger={approve} />,
          color: "#60DEC0",
          onClick: approve,
        });
      }
    }

    // CLIENT BUTTONS
    else {
      if (requirement.status === "WITH_CLIENT") {
        buttons.push({
          id: "returnToAccountant",
          label: "Return to accountant",
          icon: <ReplyIcon />,
          color: "#FA62AB",
          onClick: sendToAccountant,
          hotkey: <Hotkey hotkey="enter" includeModifier onTrigger={sendToAccountant} />,
        });
      }
    }

    return buttons;
  };

  return (
    <>
      {getButtons().map(({ id, label, color, icon, onClick, hotkey }) => (
        <ActionButton
          key={id}
          label={label}
          loading={loading}
          color={color}
          icon={icon}
          hotkey={hotkey}
          onClick={onClick}
        />
      ))}
    </>
  );
};

export default RequirementActionButtons;
