import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { chain, debounce, includes, toLower } from "lodash";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ClientStatusEnum, ClientStatusEnumColour, ClientStatusEnumDisplay } from "../../enums/ClientStatus";
import useSessionStorageService from "../../hooks/SessionStorageService";
import { ClientType } from "../../types";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

interface ClientSearchProps {
  clients: ClientType[];
  onSearchResults: (results: ClientType[]) => void;
  hasSearchChip?: boolean;
}

const SearchChip = ({ label, ...rest }: Partial<ChipProps>) => {
  return <Chip size="medium" sx={{ ml: 1.5 }} label={label} {...rest} />;
};

const ClientSearch = ({ onSearchResults, clients, hasSearchChip = true }: ClientSearchProps) => {
  const sessionStorageService = useSessionStorageService();
  const [hideCompleted, setHideCompleted] = useState(false);
  const [statusFilter, setStatusFilter] = useState<ClientStatusEnum>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<ClientType[]>();

  // manage the value of the search field separately from the actual search term to allow for debounce
  const [searchFieldValue, setSearchFieldValue] = useState<string>("");

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSearchFieldValue(event.target.value);
  };

  const doSearch = useCallback(
    (clients: ClientType[], status: ClientStatusEnum | undefined, name: string, hideCompleted: boolean) => {
      const searchResults = chain(clients)
        .filter((client) => (status ? client.status === status : true))
        .filter((client) => (name ? includes(toLower(client.name), toLower(name)) : true))
        .filter((client) => (hideCompleted ? client.status !== ClientStatusEnum.ALLCOMPLETED : true))
        .orderBy((client) => client.name)
        .value();
      setSearchResults(searchResults);
    },
    []
  );

  const handleClear = () => {
    setSearchFieldValue("");
    setSearchTerm("");
  };

  const debouncedSetSearchTerm = useMemo(
    () =>
      debounce((newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
      }, 150),
    []
  );

  useEffect(() => {
    debouncedSetSearchTerm(searchFieldValue);
  }, [searchFieldValue, debouncedSetSearchTerm]);

  useEffect(() => {
    doSearch(clients, statusFilter, searchTerm, hideCompleted);
  }, [searchTerm, statusFilter, clients, doSearch, hideCompleted]);

  useEffect(() => {
    if (searchResults) onSearchResults(searchResults);
  }, [searchResults, onSearchResults]);

  useEffect(() => {
    const clientStatusFilter = sessionStorageService.getClientStatusFilter();
    const clientHideCompleted = sessionStorageService.getClientHideCompleted();
    if (clientStatusFilter) setStatusFilter(clientStatusFilter as ClientStatusEnum);
    if (clientHideCompleted) setHideCompleted(JSON.parse(clientHideCompleted));
  }, [sessionStorageService]);

  useEffect(() => {
    sessionStorageService.setClientStatusFilter(statusFilter);
    sessionStorageService.setClientHideCompleted(hideCompleted);
  }, [hideCompleted, sessionStorageService, statusFilter]);

  useEffect(() => {
    if (statusFilter && hideCompleted) setHideCompleted(false);
  }, [hideCompleted, statusFilter]);

  return (
    <Box sx={{ display: "flex" }}>
      {/* SEARCH FIELD */}
      <TextField
        sx={{ flexGrow: 1 }}
        value={searchFieldValue}
        autoFocus
        placeholder="Search"
        size="small"
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} size="small">
                <CloseIcon sx={{ color: "primary.main" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* SEARCH CHIPS */}
      {hasSearchChip && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SearchChip
            label="All"
            color={statusFilter === undefined ? "primary" : "neutral"}
            onClick={() => setStatusFilter(undefined)}
          />

          {(Object.keys(ClientStatusEnum) as Array<ClientStatusEnum>).map((status) => (
            <SearchChip
              label={ClientStatusEnumDisplay[status]}
              key={status}
              color={statusFilter === status ? ClientStatusEnumColour[status] : "neutral"}
              onClick={() => setStatusFilter(status)}
            />
          ))}

          <FormGroup sx={{ ml: 2 }}>
            <FormControlLabel
              control={<Switch size="small" checked={hideCompleted} />}
              disabled={!!statusFilter}
              label={<Typography variant="body2">Hide completed</Typography>}
              onChange={(event, checked) => {
                setHideCompleted(checked);
              }}
            />
          </FormGroup>
        </Box>
      )}
    </Box>
  );
};

export default ClientSearch;
