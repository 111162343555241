import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import { RequirementEventLogType } from "../../../types";
import convertServerTimestamp from "../../../utilities/convertServerTimestamp";

const formatDate = (timestamp: Timestamp): string => {
  return dayjs(convertServerTimestamp(timestamp)).format("ddd DD/MM/YYYY h:mm:ssa");
};

type EventLogsProps = {
  eventLogs: RequirementEventLogType[];
};

const EventLogs = ({ eventLogs }: EventLogsProps) => {
  return (
    <List>
      {eventLogs.map((eventLog) => (
        <ListItem key={eventLog.id} sx={{ padding: 0 }}>
          <ListItemText
            primary={
              <Typography variant="body2" display="flex" gap={0.5} alignItems="center">
                <Box component="span" fontWeight={500}>
                  {eventLog.createdByEmail}
                </Box>
                <Box component="span">{eventLog.message}</Box>
              </Typography>
            }
            secondary={<Typography variant="caption">{formatDate(eventLog.createdTimestamp)}</Typography>}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default EventLogs;
