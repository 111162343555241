import { Box, Typography, useTheme } from "@mui/material";
import { useHotkeys } from "react-hotkeys-hook";

type KeyBoxProps = {
  label: string;
};

const KeyBox = ({ label }: KeyBoxProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.grey[200],
        paddingInline: "0.25rem",
        borderRadius: "0.25rem",
        minWidth: "1.5rem",
        lineHeight: 1,
        textAlign: "center",
      }}
    >
      <Typography sx={{ fontSize: "inherit" }}>{label}</Typography>
    </Box>
  );
};

type HotkeyProps = {
  hotkey: string;
  includeModifier?: boolean;
  onTrigger: () => void;
};

const detectModifierKey = (): string => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.includes("mac")) return "⌘";
  if (userAgent.includes("win")) return "Ctrl";
  return "Ctrl";
};

const Hotkey = ({ hotkey, includeModifier = false, onTrigger }: HotkeyProps) => {
  const displayKey = hotkey.toLowerCase() === "enter" ? "↵" : hotkey;
  const modifierKey = includeModifier ? detectModifierKey() : "";
  const combinationKey = includeModifier ? `${modifierKey === "⌘" ? "meta" : "ctrl"}+${hotkey}` : hotkey;

  useHotkeys(combinationKey, onTrigger);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.25rem",
      }}
    >
      {modifierKey && <KeyBox label={modifierKey} />}
      <KeyBox label={displayKey} />
    </Box>
  );
};

export default Hotkey;
